import React from "react"
import { Link } from "gatsby"

const EmptyCart = () => {
  return (
    <div className="text-center py-4">
      <p>
        Tvoja košarica je prazna.{" "}
        <Link to="/presets">Klikni tukaj, da greš nazaj v trgovino. </Link>
      </p>
      <hr className="mt-8" />
    </div>
  )
}

export default EmptyCart
